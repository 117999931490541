// PaymentModal.js
import React, { useState } from 'react';
import { Modal, Box, TextField, Button, Typography } from '@mui/material';
import axios from 'axios';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const PaymentModal = () => {
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState('');
  const [phone, setPhone] = useState('');
  const [responseDescription, setResponseDescription] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setAmount('');
    setPhone('');
    setResponseDescription('');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post('https://payment.amberspace.co/api/stkPush', {
        amount,
        'phone':'254'+phone.slice(-9),
      });

      setResponseDescription(response.data.ResponseDescription ||response.data.errorMessage ||  'No description received');
    } catch (error) {
      setResponseDescription('Error submitting data');
    }
  };

  return (
    <div>
      <Button variant="contained" onClick={handleOpen}>
       Pay Now
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-title" variant="h6" component="h2">
            Submit Your Details
          </Typography>
          <form onSubmit={handleSubmit} >
          <TextField
  fullWidth
  margin="normal"
  label="Amount"
  variant="outlined"
  value={amount}
  onChange={(e) => {
    const value = e.target.value.replace(/\D/g, ''); // Allow only digits
   
      setAmount(value);
  
  }}
  required
  type="text"
  slotProps={{
    input: {
      inputMode: 'numeric', // Ensures numeric keyboard on mobile
    },
  }}
/>

<TextField
  fullWidth
  margin="normal"
  label="Phone Number"
  variant="outlined"
  value={phone}
  onChange={(e) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
    if (value.length <= 10) { // Prevent more than 10 digits
      setPhone(value);
    }
  }}
  required
  type="text"
  slotProps={{
    input: {
      inputMode: 'numeric', // Ensures numeric keyboard on mobile
      maxLength: 10, // Prevent more than 10 digits
    },
  }}
/>

            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </form>
          {responseDescription && (
            <Typography variant="body1" mt={2}>
     {responseDescription}
            </Typography>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default PaymentModal;
